<template>
    <div
        class="modal fade"
        :id="props.id"
        tabindex="-1"
        style="z-index: 2147483647"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body text-center">
                    {{ t('updateAppModal.title') }}
                    <p class="m-0">
                        {{ t('updateAppModal.current', { version: version }) }}
                    </p>
                </div>
                <div class="border-top d-flex flex-row">
                    <a
                        data-bs-dismiss="modal"
                        class="w-50 border-end py-2 tap-react rounded-bottom"
                        style="font-weight: 500"
                    >
                        <div class="col text-center">{{ t('misc.later') }}</div>
                    </a>
                    <a
                        data-bs-dismiss="modal"
                        class="w-50 py-2 tap-react rounded-bottom fw-medium"
                        @click="emitConfirmUpdate"
                    >
                        <div class="col text-center">
                            {{ t('updateAppModal.updateNow') }}
                        </div>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import { useI18n } from 'vue-i18n';

const { t } = useI18n();
const props = defineProps(['id']);
const emit = defineEmits(['confirmUpdate']);
const version = process.env.VUE_APP_VERSION;

const emitConfirmUpdate = () => {
    emit('confirmUpdate');
};
</script>
