import { defineStore } from 'pinia';
import router from '@/router';
import axios from 'axios';
import i18n from '@/i18n';
import moment from 'moment-timezone';
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

function timeUntil(date) {
    const now = moment();
    var duration = moment.duration(date.diff(now));

    // Get Days and subtract from duration
    var days = Math.floor(duration.asDays());
    duration.subtract(moment.duration(days, 'days'));

    // Get hours and subtract from duration
    var hours = duration.hours();
    duration.subtract(moment.duration(hours, 'hours'));

    var minutes = duration.minutes();
    duration.subtract(moment.duration(minutes, 'minutes'));

    var seconds = duration.seconds();
    duration.subtract(moment.duration(seconds, 'seconds'));

    return {
        days,
        hours,
        minutes,
        seconds,
    };
}

export const useUserStore = defineStore('user', {
    state: () => ({
        user: {
            id: null,
            first_name: null,
            last_name: null,
            email: null,
            subscriptionComputed: {},
            accreditations: [],
            loading: true,
            oneOfSubscriptionExpired: false,
            invoices: [],
        },
        token: null,
    }),
    actions: {
        async forceLogin(token) {
            this.$state.token = token;
            let res = await axios.get('user/current', {
                headers: {
                    Authorization: 'Bearer ' + token,
                },
            });
            this.setUserDetails(res.data);
            router.push('/more');
        },
        async setUserDetails(data) {
            axios.defaults.headers.common['Authorization'] =
                'Bearer ' + data.token;
            this.$state.user = data.user;
            this.$state.token = data.token;

            // Setting locale on login
            if (data.user.language == 'French') {
                i18n.global.locale = 'fr';
            } else {
                i18n.global.locale = 'en';
            }
        },
        async fetchUser() {
            axios
                .get('user/current')
                .then((res) => {
                    // Need to set user details before we do any of these
                    // transformations or else whatever transformations we
                    // made will be reset

                    this.setUserDetails(res.data);
                    res.data.user.accreditations.forEach((acc, index) => {
                        const start = moment(acc.start);
                        const expiry = moment(acc.end);

                        // Show expiresIn for when the subscription is still active
                        // so we can tell the user the accurate time of when their
                        // subscription will end
                        res.data.user.accreditations[index].expiresIn =
                            timeUntil(expiry);

                        res.data.user.accreditations[index].startsIn =
                            timeUntil(start);

                        // We use expiresInHumans instead when the subscription
                        // has already expired. This is less specific
                        res.data.user.accreditations[index].expiresInHumans =
                            expiry.fromNow();

                        res.data.user.accreditations[index].startsInHumans =
                            start.fromNow();

                        if (acc.expired)
                            this.$state.user.oneOfSubscriptionExpired = true;
                        res.data.user.accreditations[index].length =
                            expiry.diff(start, 'days');
                    });
                })
                .catch((e) => {
                    // If unauthorised, clear the store and send user to login page
                    if (e.response.status == 401) {
                        this.clearUser();
                        router.push('/login');
                    }
                    console.error(e);
                });

            this.$state.loading = false;
        },
        async fetchInvoices() {
            const res = await axios.get('user/invoices');
            this.$state.user.invoices = res.data;
        },
        clearUser() {
            this.$reset();
        },
    },
    persist: true,
});
