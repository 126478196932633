import { createI18n } from 'vue-i18n';
import messages from '@/translations';

// Check if user already exists in local storage
const user = JSON.parse(window.localStorage.getItem('user'));
var locale = 'fr';

if (user) {
    if (user.user.language === 'French') {
        locale = 'fr';
    } else if (user.user.language === 'English') {
        locale = 'en';
    }
}

// 2. Create i18n instance with options
const i18n = createI18n({
    locale, // set locale
    allowComposition: true, // you need to specify that!
    fallbackLocale: 'en', // set fallback locale
    messages, // set locale messages
});

export default i18n;
