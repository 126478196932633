import { defineStore } from 'pinia';
import axios from 'axios';

export const useRemoteSettingsStore = defineStore('remoteSettings', {
    state: () => ({
        settings: {},
    }),
    actions: {
        async fetchSettings() {
            let res = await axios.get('remote-settings');
            this.$state.settings = res.data;
        },
        clearRemoteSettings() {
            this.$reset();
        },
    },
    persist: true,
});
