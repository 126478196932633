import { useUserStore } from '@/stores/user-store';
import { storeToRefs } from 'pinia';

export const getLocale = () => {
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    if (user.value.language == 'French') {
        return 'fr';
    } else {
        return 'en';
    }
};
