<template>
    <div class="dark-header bg-dark">
        <div v-if="!GLOBAL_VAR_IS_PRODUCTION">
            <div
                class="d-flex flex-wrap justify-content-center align-items-center"
                style="gap: 0.5rem"
            >
                <div
                    v-for="i in 50"
                    :key="i"
                    class="text-light"
                    style="opacity: 0.3"
                >
                    PROTOTYPE
                </div>
            </div>
        </div>
        <div
            v-else
            style="width: 100%; height: 100%"
            class="dark-header-overlay"
        ></div>
    </div>
</template>

<script setup>
import { inject } from 'vue';
const GLOBAL_VAR_IS_PRODUCTION = inject('GLOBAL_VAR_IS_PRODUCTION');
if (GLOBAL_VAR_IS_PRODUCTION == false) {
    console.log('=== YACHTSIDE DEVELOPMENT BUILD ===');
}
</script>

<style scoped>
.dark-header {
    position: absolute;
    top: 0;
    height: 180px;
    width: 100%;
    z-index: -1;
}

.dark-header-overlay {
    z-index: 0;
    background-repeat: repeat-x, repeat-x;
    background-position-y: -50px, 10px;
    background-position-x: -10px, -40px;
    background-size: 50%;
    background-image: url('~@/assets/overlay.png'), url('~@/assets/overlay.png');
    opacity: 0.2;
}
</style>
