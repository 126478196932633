<template>
    <div
        class="row bottom-bar fixed-bottom text-dark border-top pt-2 g-1"
        style="background: white"
    >
        <div
            v-for="item in menuItems"
            :key="item.name"
            :class="[
                {
                    'text-dark': route.path === item.path,
                },
            ]"
            :style="[
                route.path !== item.path ? 'color: var(--bs-gray-600)' : '',
            ]"
            @click="router.push(item.path)"
            :data-count="[item.path == '/messages' ? '10' : '']"
            class="bottom-bar-item col d-flex flex-column justify-content-center align-items-center text-center"
            style="gap: 5px"
        >
            <span
                :class="[item.path == '/messages' ? 'position-relative' : '']"
                v-if="item.path == '/messages' && unreads != 0"
            >
                <i :class="item.icon"></i>
                <div class="icon-badge">{{ unreads }}</div>
            </span>
            <span v-else>
                <i :class="item.icon"></i>
            </span>
            <span style="font-weight: 500">{{ t(item.label) }}</span>
        </div>
    </div>
</template>

<style scoped>
.bottom-bar-item i {
    font-size: 1rem;
}

.bottom-bar {
    padding-bottom: constant(
        safe-area-inset-bottom,
        0.5rem
    ); /* For Safari 11.0 */
    padding-bottom: env(safe-area-inset-bottom, 0.5rem);
}

.bottom-bar-item {
    font-size: 0.7rem;
}

.icon-badge {
    background: var(--bs-danger);
    border-radius: 100%;
    width: 1.1rem;
    height: 1.1rem;
    position: absolute;
    right: -13px;
    top: -9px;
    font-size: 0.6rem;
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
}
</style>

<script setup>
import { computed } from 'vue';
import { useI18n } from 'vue-i18n';
import { storeToRefs } from 'pinia';
import { useRouter, useRoute } from 'vue-router';
import { useMessageStore } from '@/stores/messages-store';
// import { useRemoteSettingsStore } from '@/stores/remote-settings-store';

const { t } = useI18n();
const router = useRouter();
const route = useRoute();

const messageStore = useMessageStore();
const { unreads } = storeToRefs(messageStore);

// const remoteSettingsStore = useRemoteSettingsStore();
// const { settings } = storeToRefs(remoteSettingsStore);

const menuItems = computed(() => {
    const defaultItems = [
        {
            path: '/',
            icon: 'fa-solid fa-house',
            label: 'bottomBar.home',
        },
        {
            path: '/yachts',
            icon: 'fa-solid fa-ship',
            label: 'bottomBar.browse',
        },
        {
            path: '/messages',
            icon: 'fa-solid fa-message',
            label: 'bottomBar.messages',
        },
        {
            path: '/bookings',
            icon: 'fa-solid fa-calendar',
            label: 'bottomBar.bookings',
        },
    ];

    // DISABLE FOOD AND SERVICES
    // Search for "DISABLE FOOD AND SERVICES" to see all commments
    // if (!settings.value.disable_services) {
    //     defaultItems.push({
    //         path: '/services',
    //         icon: 'fa-solid fa-bell-concierge',
    //         label: 'bottomBar.services',
    //     });
    // }

    /**
     * Sandwiched in between so we have to do this push
     * nice n easy
     */
    defaultItems.push({
        path: '/more',
        icon: 'fa-solid fa-ellipsis',
        label: 'bottomBar.more',
    });

    return defaultItems;
});
</script>
