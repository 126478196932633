import App from './App.vue';
import router from '@/router';
import { createApp } from 'vue';
import { createPinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import './axios';
import '@/scss/main.scss';
import './registerServiceWorker';
import 'bootstrap/dist/js/bootstrap.min.js';

import I18n from '@/i18n';
import ReadMore from 'vue-read-more';
import moment from 'moment-timezone';
import { setupCalendar } from 'v-calendar';

let neoyotApp = createApp(App);

const i18n = I18n;
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

/**
 * We are stopping the use of globalProperties to use injections instead
 * Vue 3 has removed accessing globalProperties, and we have to resort to using
 * hacks, which is undocumented and therefore discouraged.
 */
neoyotApp.provide(
    'GLOBAL_VAR_IS_PRODUCTION',
    process.env.VUE_APP_ENV == 'PRODUCTION'
);
neoyotApp.provide('moment', moment);
neoyotApp.provide('GLOBAL_VAR_API_URL', process.env.VUE_APP_BACKEND_URL);
neoyotApp.provide('GLOBAL_VAR_API_KEY', process.env.VUE_PUSHER_APP_KEY);
neoyotApp.provide(
    'GLOBAL_VAR_BACKEND_STORAGE_URL',
    process.env.VUE_APP_BACKEND_STORAGE_URL
);
neoyotApp.provide(
    'GLOBAL_VAR_SAFE_PADDING_TOP',
    'calc(1.5rem + env(safe-area-inset-top))'
);
neoyotApp.provide('GLOBAL_VAR_SAFE_PADDING_BOTTOM', '4.5rem');

neoyotApp
    .use(pinia)
    .use(router)
    .use(ReadMore)
    .use(i18n)
    .use(setupCalendar, {})
    .mount('#app');
