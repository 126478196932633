<template>
    <div
        class="modal fade"
        :id="props.id"
        tabindex="-1"
        style="z-index: 2147483646"
        data-bs-backdrop="static"
        data-bs-keyboard="false"
    >
        <div class="modal-dialog modal-dialog-centered modal-sm">
            <div class="modal-content">
                <div class="modal-body text-start p-4">
                    <div
                        class="d-flex flex-column align-items-center mb-4"
                        style="gap: 1rem"
                    >
                        <img
                            src="@/assets/app-icon.png"
                            alt=""
                            style="width: 4rem"
                        />
                        <h5 class="text-center">
                            {{ t('installModal.heading') }}
                        </h5>
                    </div>
                    <div v-if="usingiOS">
                        <!-- <div class="mb-4">
                            <h5 class="mb-3">1. Tap share button</h5>
                            <img
                                src="@/assets/app-install-instructions/en_1.png"
                                alt=""
                                style="object-fit: contain; width: 100%"
                            />
                        </div>
                        <div class="mb-4">
                            <h5 class="mb-3">2. Select "Add to homescreen"</h5>
                            <img
                                src="@/assets/app-install-instructions/en_2.png"
                                alt=""
                                style="object-fit: contain; width: 100%"
                            />
                        </div>
                        <h5 class="mb-3">3. Tap "Add"</h5>
                        <img
                            src="@/assets/app-install-instructions/en_3.png"
                            alt=""
                            style="object-fit: contain; width: 100%"
                        />
                        <a
                            href="#"
                            data-bs-dismiss="modal"
                            class="mt-4 btn bg-dark text-light w-100"
                            >Dismiss</a
                        > -->

                        {{ t('installModal.tap') }}
                        <img
                            src="@/assets/ios-share-icon.png"
                            style="width: 20px; vertical-align: top"
                            class="mx-1"
                            alt="Share icon"
                        />
                        {{ t('installModal.thenAdd') }}
                        <a
                            href="#"
                            data-bs-dismiss="modal"
                            class="btn bg-dark text-light w-100 mt-5"
                        >
                            {{ t('installModal.dismissPassive') }}
                        </a>
                    </div>
                    <div v-else>
                        <a
                            href="#"
                            @click="androidShowPrompt"
                            data-bs-dismiss="modal"
                            id="androidInstallAppButton"
                            class="btn btn-primary w-100"
                            ><i class="fa fa-arrow-down me-2"></i
                            >{{ t('installModal.installButton') }}</a
                        >
                        <a
                            href="#"
                            data-bs-dismiss="modal"
                            class="text-muted py-2 mt-2 d-block w-100 text-center"
                        >
                            {{ t('installModal.dismissButton') }}
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { defineProps, computed } from 'vue';
import { useI18n } from 'vue-i18n';
const { t } = useI18n();
let deferredPrompt;

window.addEventListener('beforeinstallprompt', (e) => {
    deferredPrompt = e;
});

async function androidShowPrompt() {
    if (deferredPrompt !== null) {
        deferredPrompt.prompt();
        const { outcome } = await deferredPrompt.userChoice;
        if (outcome === 'accepted') {
            deferredPrompt = null;
        }
    }
}

const props = defineProps(['id']);

const usingiOS = computed(() => {
    return (
        [
            'iPad Simulator',
            'iPhone Simulator',
            'iPod Simulator',
            'iPad',
            'iPhone',
            'iPod',
        ].includes(navigator.platform) ||
        // iPad on iOS 13 detection
        (navigator.userAgent.includes('Mac') && 'ontouchend' in document)
    );
});
</script>
