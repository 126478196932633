<template>
    <div
        class="d-flex flex-column justify-content-center align-items-start text-start"
        style="
            height: 100vh;
            background: var(--bs-dark);
            color: var(--bs-light);
            padding: 2rem;
        "
    >
        <img src="@/assets/logo-text.png" class="mb-4" style="width: 120px" />
        <h1 v-if="i18n.locale.value == 'fr'" class="mb-4">
            {{ settings.maintenance_heading_fr }}
        </h1>
        <h1 v-else class="mb-4">{{ settings.maintenance_heading_en }}</h1>
        <div class="bg-primary mb-4" style="height: 3px; width: 100%"></div>
        <p v-if="i18n.locale.value == 'fr'">
            {{ settings.maintenance_message_fr }}
        </p>
        <p v-else>
            {{ settings.maintenance_message_en }}
        </p>
        <div
            class="d-flex flex-row justify-content-between align-items-center w-100"
        >
            <a href="#" class="btn btn-secondary bg-dark">{{
                t('misc.refresh')
            }}</a>
            <div>
                <a
                    href="#"
                    class="p-3"
                    v-if="i18n.locale.value == 'fr'"
                    @click="switchLang('en')"
                    >English</a
                >
                <a
                    href="#"
                    class="p-3"
                    v-if="i18n.locale.value == 'en'"
                    @click="switchLang('fr')"
                    >Français</a
                >
            </div>
        </div>
    </div>
</template>

<script setup>
import { useRemoteSettingsStore } from '@/stores/remote-settings-store';
import { storeToRefs } from 'pinia';
import { useI18n } from 'vue-i18n';
const i18n = useI18n();
const { t } = useI18n();
const remoteSettingsStore = useRemoteSettingsStore();
const { settings } = storeToRefs(remoteSettingsStore);

function switchLang(lang) {
    i18n.locale.value = lang;
}

function refresh() {
    remoteSettingsStore.fetchSettings();
}

setInterval(() => {
    if (settings.value.maintenance_mode) refresh();
}, 15000); // check every 15 seconds
</script>

<style scoped>
body {
    background: black;
}
</style>
