import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/stores/user-store';
import { useRemoteSettingsStore } from '@/stores/remote-settings-store';
import moment from 'moment-timezone';
import { getLocale } from '@/getLocale';
moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

function fetchRemoteSettings() {
    const remoteSettingsStore = useRemoteSettingsStore();
    remoteSettingsStore.fetchSettings();
}

async function auth() {
    fetchRemoteSettings(); // Fetch on every page load to see if app under maintenance
    moment.locale(getLocale());
    const userStore = useUserStore();
    userStore.user.loading = true;
    userStore.fetchUser(); // User is re-fetched on every page load
    if (userStore.user.id) {
        return true;
    }
    return '/login';
}

function guest() {
    fetchRemoteSettings(); // Fetch on every request to see if app under maintenance
    if (useUserStore().user.id) {
        return '/';
    }
    return true;
}

const routes = [
    {
        path: '/',
        component: () => import('@/views/Home/Home'),
        beforeEnter: [auth],
    },
    {
        path: '/forcelogin/:token',
        component: () => import('@/views/ForceLogin'),
    },
    {
        path: '/invitation/:token',
        component: () => import('@/views/AppInvitation'),
    },
    {
        path: '/login/:reset?',
        component: () => import('@/views/Login'),
        beforeEnter: [guest],
    },
    {
        path: '/reset-password/:token',
        component: () => import('@/views/ResetPassword.vue'),
    },
    {
        path: '/yachts',
        component: () => import('@/views/Yachts/Yachts'),
        beforeEnter: [auth],
    },
    {
        path: '/yachts/:id',
        component: () => import('@/views/Yachts/ViewYacht'),
        beforeEnter: [auth],
    },
    {
        path: '/messages',
        component: () => import('@/views/Messages/Messages'),
        beforeEnter: [auth],
    },
    {
        path: '/messages/conversation/:id',
        component: () => import('@/views/Messages/ViewConversation'),
        beforeEnter: [auth],
    },
    {
        path: '/bookings',
        component: () => import('@/views/Bookings/Bookings'),
        beforeEnter: [auth],
    },
    {
        path: '/bookings/book/yacht/:id',
        component: () => import('@/views/Yachts/BookNow/BookNow'),
        beforeEnter: [auth],
    },
    {
        path: '/bookings/:id',
        component: () => import('@/views/Bookings/ViewBooking'),
        beforeEnter: [auth],
    },
    {
        path: '/bookings/thankyou',
        component: () => import('@/views/Yachts/BookNow/ThankYou'),
        beforeEnter: [auth],
    },
    {
        path: '/more',
        component: () => import('@/views/More/More'),
        beforeEnter: [auth],
    },
    {
        path: '/more/my-account',
        component: () => import('@/views/User/MyAccount'),
        beforeEnter: [auth],
    },
    {
        path: '/more/my-subscription',
        component: () => import('@/views/More/MySubscription'),
        beforeEnter: [auth],
    },
    {
        path: '/more/invoices',
        component: () => import('@/views/More/Invoices'),
        beforeEnter: [auth],
    },
    {
        path: '/more/feedback',
        component: () => import('@/views/More/SendFeedback'),
        beforeEnter: [auth],
    },
    {
        path: '/more/about-app',
        component: () => import('@/views/More/AboutApp'),
        beforeEnter: [auth],
    },
    {
        path: '/more/about-app/tos',
        component: () => import('@/views/More/TermsOfService'),
        beforeEnter: [auth],
    },
    {
        path: '/more/about-app/osl',
        component: () => import('@/views/More/OpenSourceLicenses'),
        beforeEnter: [auth],
    },
    {
        path: '/more/contact-support',
        component: () => import('@/views/More/ContactSupport'),
        beforeEnter: [auth],
    },
    {
        path: '/articles/:id',
        component: () => import('@/views/Articles/ViewArticle'),
        beforeEnter: [auth],
    },
    // DISABLE FOOD AND SERVICES
    // Search for "DISABLE FOOD AND SERVICES" to see all commments
    // {
    //     path: '/services',
    //     component: () => import('@/views/Services/Services'),
    //     beforeEnter: [auth],
    // },
    // {
    //     path: '/services/food-beverages',
    //     component: () =>
    //         import('@/views/Services/FoodAndBeverages/FoodAndBeverages'),
    //     beforeEnter: [auth],
    // },
    // {
    //     path: '/services/:serviceId',
    //     component: () => import('@/views/Services/ViewService'),
    //     beforeEnter: [auth],
    // },
    // {
    //     path: '/services/:serviceId/:collectionId',
    //     component: () => import('@/views/Services/ViewCollection'),
    //     beforeEnter: [auth],
    // },
    {
        path: '/:pathMatch(.*)*',
        component: () => import('@/views/PageNotFound'),
    },
];

const router = createRouter({
    scrollBehavior() {
        // always scroll to top
        return { top: 0 };
    },
    history: createWebHistory(),
    routes,
});

export default router;
