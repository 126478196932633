import { defineStore } from 'pinia';

export const useMessageStore = () => {
    const innerStore = defineStore('messages', {
        state: () => ({
            conversations: [],
            unreads: 0,
        }),
        actions: {
            clearMessages() {
                this.$reset();
            },
        },
        persist: true,
    });
    const store = innerStore();
    return store;
};
